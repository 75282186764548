import Account from "../pages/Account";
import Cart from "../pages/Cart"
import Checkout from "../pages/Checkout"
import Wishlist from "../pages/Wishlist"

const userRoute = [
    {   
        path:'/wishlist',
        component: <Wishlist />
    },
    // {
    //     path:'/cart',
    //     component: <Cart />
    // },
    {
        path:'/checkout',
        component: <Checkout />
    },
    {
        path:'/dashboard',
        component: <Account />
    }
];
export default userRoute
