import React, { useEffect, useState } from 'react'
import Layout from '../partials/Layout'
import {useSelector, useDispatch} from 'react-redux'
import { getData } from '../../actions/Useraction';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {getpData} from '../pages/apis/service'
import Banner from '../partials/Banner';

function Home() {
const [categories, setCategories] = useState([]);
const [celebrations, setCelebrations] = useState([]);
const [offer, setOffer] = useState();
const dispatch = useDispatch();
// const state = useSelector((state)=>{
//   return state.usr; 
// })



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive_second = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


useEffect(()=>{
  fetchCategories()
  getCelbs();
  getOffers()
}, [dispatch])

const getOffers = async()=>{
  try{
  var res = await getpData('get-offers');
      console.log("offers", res.data.output);
      setOffer(res.data.output)
        }  
  catch(error)
  {
    console.error('Server Error:', error);
    //return { error: true, message: error.message };
  }       
}

const getCelbs = async()=>{
  try{
  var res = await getpData('get-celebration-data');
      //console.log("cele", res.data.output);
      setCelebrations(res.data.output)
        }  
  catch(error)
  {
    console.error('Server Error:', error);
    //return { error: true, message: error.message };
  }       
}

const fetchCategories = async () => {
  try {
    const data = await dispatch(getData('get-categories?get=front_display'));
    console.log("rec ", data.payload.output);
    setCategories(data.payload.output)
    
  } catch (error) {
    console.error('Error fetching categories:', error);
  }
};


  return (
    <>
 
 <Layout>

  {/*== Banner // Slider Area Start ==*/}
  <section id="banner-area" className="banner__2">
    <Banner />
  </section>
  {/*== Banner Slider End ==*/}
<div>
  {
    offer?.imag === null ?
  (
<marquee bgcolor="#935337" 
                 direction="left" loop="" style={{ color:'#fff' }}>{offer?.title}</marquee>
  )
: null  }
<section id="featured-products-area" className="p-9">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          {/* Section Title Start */}
          <div className="section-title">
            <h2>Celebrations </h2>
            {/* <p>Trending stunning Unique</p> */}
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="products-wrapper">
            {/* <div className="new-products-carousel owl-carousel"> */}
            <>
            <Carousel  
            
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={false}
            autoPlay={false}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
            arrows={false}
            draggable={false}
          >
           {
  celebrations.length > 0 ? (
    celebrations.map((pr) => (
      <div key={pr.id} className="single-product-item text-center" style={{ margin: '0 10px' }}>
        <figure className="product-thumb">
          <a href={`shop/${pr?.slug}`}>
            <img src={pr?.imag} alt="Products" className="img-fluid" style={{ width: '290px', height: '359px' }} />
          </a>
        </figure>
        <div className="product-details">
          <h2><a href={`shop/${pr?.slug}`}>{pr?.title}</a></h2>
        </div>
      </div>  
    ))
  ) : (
    <div className="col-lg-4 col-sm-6">
      <p>There is no data.</p>
    </div>
  )
}
           </Carousel>
            </>
          </div>
        </div>
      </div>
    </div>
  </section>
  

  {/*== New Produts Two End ==*/}
  {/*== Sale Products Area Start ==*/}
  <section id="sale-products-area" style={{backgroundColor:'#BCA186'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="sale-product-wrapper">
            <div className="sale-product-carousel owl-carousel">
              {/* Single Sale Product Start */}
              <div className="single-sale-product">
                {
                  offer?.imag ?
                  <div className="row">
                  <div className="col-lg-6 col-md-7 mt-5 m-md-auto">
                    <div className="sale-product-details text-center text-md-right">
                      <h3 style={{color:'#ffffff'}}>{offer?.title}</h3>
                      <h2 style={{color:'#ffffff'}}>{offer?.sub_title}</h2>
                      {
                        offer.desc !== '' ? <p style={{color:'#ffffff'}}>{offer?.desc}</p> : <></>
                      }
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto order-first order-md-last">
                    <figure className="sale-product-thumb">
                      <a href="/shop"><img src={offer?.imag} alt="Products" className="img-fluid" /></a>
                    </figure>
                  </div>
                </div> 
                :
                <div className="row">
                <div className="col-lg-6 col-md-7 mt-5 m-md-auto">
                  <div className="sale-product-details text-center text-md-right">
                    <h3 style={{color:'#ffffff'}}>Act Fast – Sale Ends Soon</h3>
                    <h2 style={{color:'#ffffff'}}>Kickoff Offer: Flat 50% Off on All Items!</h2>
             
                    <p style={{color:'#ffffff'}}>Wrap your family in luxury with our stylish, super-soft towels. Available in vibrant colors and chic designs, they combine comfort and durability for everyday elegance</p>
                    <a href="/shop" className="btn btn-add-to-cartt text-white">Shop Now</a>
                  </div>
                </div>
                <div className="col-md-5 ml-auto order-first order-md-last">
                  <figure className="sale-product-thumb">
                    <a href="#"><img src="/assets/img/slider/towel_image1.jpg" alt="Products" className="img-fluid" /></a>
                  </figure>
                </div>
              </div> 
                }
                
              </div>
              {/* Single Sale Product End */}

              {/* Single Sale Product End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*== Sale Product Area End ==*/}
  {/*== Featured Products Area Start ==*/}
  <section id="featured-products-area" className="p-9">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          {/* Section Title Start */}
          <div className="section-title">
            <h2>Shop By Categories</h2>
            {/* <p>Trending stunning Unique</p> */}
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="products-wrapper">
            {/* <div className="new-products-carousel owl-carousel"> */}
            <>
            <Carousel  
            
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
            
          >
           {
  categories.length > 0 ? (
    categories.map((pr) => (
      <div key={pr.id} className="single-product-item text-center" style={{ margin: '0 10px' }}>
        <figure className="product-thumb">
          <a href={`shop/${pr?.slug}`}>
            <img src={pr?.imag} alt="Products" className="img-fluid" style={{ width: '290px', height: '359px' }} />
          </a>
        </figure>
        <div className="product-details">
          <h2><a href={`shop/${pr?.slug}`}>{pr?.name}</a></h2>
        </div>
      </div>  
    ))
  ) : (
    <div className="col-lg-4 col-sm-6">
      <p>There is no data.</p>
    </div>
  )
}
           </Carousel>
            </>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  {/*== Testimonial Area Start ==*/}
  <section id="testimonial-area">
    <div className="ruby-container" style={{padding:0}}>
      <div className="testimonial-wrapper">
        <div className="row">
          <div className="col-lg-12 text-center">
            {/* Section Title Start */}
            <div className="section-title">
              <h2>What People Say</h2>
              <p className='text-white'>Testimonials</p>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 m-auto text-center">
            <div className="testimonial-content-wrap">
              <div id="testimonialCarousel" className="owl-carousel">
                <div className="single-testimonial-item">
                  <p style={{color:'#ffffff'}}>"I absolutely love these towels! They are incredibly soft and feel luxurious against the skin. The absorbency is amazing, drying me off quickly without feeling heavy or damp. Even after multiple washes, they maintain their plush texture and vibrant color. I appreciate the attention to detail in the stitching and design—it adds a touch of elegance to my bathroom. Highly recommend these towels to anyone looking for a perfect blend of comfort and quality!"</p>
                  {/* <h3 className="client-name text-white">Luis Manrata</h3>
                  <span className="client-email text-white">you@email.here</span> */}
                </div>
                <div className="single-testimonial-item">
                  <p style={{color:'#ffffff'}}>"These towels are a game-changer! The material is so soft and gentle, even on sensitive skin. They dry super quickly, which is perfect for busy mornings, and they don’t lose their fluffiness over time. The quality is top-notch, and they add a luxurious feel to my bathroom. I’ve even received compliments from guests! Will definitely be buying more."</p>
                  {/* <h3 className="client-name text-white">John Dibba</h3>
                  <span className="client-email text-white">you@email.here</span> */}
                </div>
                <div className="single-testimonial-item">
                  <p style={{color:'#ffffff'}}>"I’m so impressed with these towels! They strike the perfect balance between softness and durability. They absorb water effortlessly and still feel lightweight. Plus, they look stunning! The elegant design makes them a beautiful addition to my home, and the eco-friendly aspect is an added bonus. I’m already recommending them to friends and family!"</p>
                  {/* <h3 className="client-name text-white">Alex Tuntuni</h3>
                  <span className="client-email text-white">you@email.here</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*== Testimonial Area End ==*/}
  {/*== Newsletter Area Start ==*/}
  {/* <section id="newsletter-area" className="p-9">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
    
          <div className="section-title">
            <h2>Join The Newsletter</h2>
            <p>Sign Up for Our Newsletter</p>
          </div>
      
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 m-auto">
          <div className="newsletter-form-wrap">
            <form id="subscribe-form" action="https://d29u17ylf1ylz9.cloudfront.net/ruby-v2/ruby/assets/php/subscribe.php" method="post">
              <input id="subscribe" type="email" name="email" placeholder="Enter your Email  Here" required />
              <button type="submit" className="btn-long-arrow">Subscribe</button>
              <div id="result" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/*== Newsletter Area End ==*/}
  {/* Footer Area Start */}
  
  </Layout>

  


    </>
  )
}

export default Home 