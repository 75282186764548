import React, { useEffect, useState } from 'react'
import Layout from '../../partials/Layout'
import papi from '../../../actions/papi';
function Terms() {
  const [pag, setPag] = useState([]);
  useEffect(()=>{
    fetchPage('term-conditions')
  }, [])

  const fetchPage = async (pg)=>{
    try{
      
      const response = await papi.get(`get-page/${pg}`);
      console.log("fetched products", response.data);
      if(response.data.output)
      {
        setPag(response.data.output)
      }
      
    }
    catch(error){
      console.log("error", error)
    }
  }

  return (
    <Layout>
        <div>
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>{pag?.title}</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/terms-conditions" className="active breadcrumb_active">{pag?.sub_title}</a></li>
            </ul>
          </div>  
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9 pt-5">
    <div className="container">
     
      <div className="row">
        {/* Contact Form Start */}
        <div className="col-lg-12 m-auto">
          <div className="contact-form-wrap">
            {/* <h2>" The beginning is the most important part of the work "</h2> */}

            <div className="col-md-12 col-lg-12">
            <div dangerouslySetInnerHTML={{ __html: pag?.desc }}></div>
     

      </div>

           
          </div>
        </div>
        {/* Contact Form End */}
      </div>
    </div>
  </div>
</div>
    </Layout>
  )
}

export default Terms