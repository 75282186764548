import axios from "axios";
const papi = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
      'Content-Type': 'application/json',  
    },
    withCredentials: true,
  });


export default papi



// for get method -> headers: {
//     'Accept': 'application/json',  
//   },

//   for post method -> headers: {
//     'Content-Type': 'application/json', 
//   }