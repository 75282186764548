import React, { useEffect, useState } from 'react'
import Layout from '../partials/Layout'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import MyInput2 from './my_component/MyInput2';
import MyButton from './my_component/MyButton';
import { toast } from 'react-toastify';
import { shippingAddressValidate } from './validations/validate';
import api from '../../actions/api';
import RazorPayment from './my_component/RazorPayment';
import { getpData } from './apis/service';
import papi from '../../actions/papi';

const initialState = {
  fname:'',
  lname:'',
  phone:'',
  address:'',
  pincode:'',
  landmark:'',
  city:'',
  note:''
}

function Checkout() {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [products, setProducts] = useState();
  const [shippingAddress, setShippingAddress] = useState([]);
  const [checkedVal, setCheckedVal] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [err, setErr] = useState(true);
  const [orderMsg, setOrderMsg] = useState("");
  const [errName, setErrName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [data, setData] = useState(initialState);
  const [paymentMethod, setPaymentMethod] = useState("cashOnDelivery");
  const state = useSelector(state => state.usr);
  const [loading, setLoading] = useState(false);
  const [shippingNotes, setShippingNotes] = useState('');
  const [orderId, setOrderId] = useState('');
  const [activateRazor, setActivateRazor] = useState(false);
  const [user, setUser] = useState({});
  const [offer, setOffer] = useState();
  const [offerCode, setOfferCode] = useState('');
  const [offerApplicabe, setOfferApplicable] = useState(false);
  const [offerData, setOfferData] = useState({});

useEffect(()=>{
  getShippingAddress()

  let filteredProducts = [];  
    if(state?.cart?.length>0)
        {
          filteredProducts = state?.cart?.filter(item => item?.cart_dtl?.stock > 0);   
        }
       // console.log('filteredProducts', filteredProducts)
    // if(filteredProducts.length === 0)
    // {
    //   window.history.back();
    // }    
    setProducts(filteredProducts);
    getUserInfo()
    getOffers()

}, [isLoggedIn, setShowForm, state])

const getOffers = async()=>{
  try{
  var res = await getpData('get-offers');
      console.log("offers", res.data.output);
      setOffer(res.data.output)
        }  
  catch(error)
  {
    console.error('Server Error:', error);
    //return { error: true, message: error.message };
  }       
}
const getUserInfo = async ()=>{
  try {
    const headers = {
      'Accept': 'application/json',
    };
    const res = await api.get('get-user', {headers});
     console.log("user info ", res.data.data.id); 
    if(res.data.data.id)
    {
      setUser(res.data.data)
    }
    
  } catch (error) {
    console.error('Error in shipping address:', error, error.message);
}
}

  const getShippingAddress = async ()=>{
    try {
      const headers = {
        'Accept': 'application/json',
      };
      const res = await api.get('get-shipping-address', {headers});
      // console.log("shipping address ", res?.data?.error, res?.data?.data); 
        setShippingAddress(res?.data?.data)
        setShowForm(res?.data?.data?.length > 0 ? false : true)
      
    } catch (error) {
      console.error('Error in shipping address:', error, error.message);
  }
}

  const placeOrder = ()=>{
    console.log("form data", data)

    if(products?.length===0)
      { 
          toast("Your cart is empty", {
            position: "top-right",
            autoClose: 3000,
            type:'warning'
        })
        return;
      }
      if(shippingAddress?.length>0)
        { 
          if(showForm===false && checkedVal === '')
          {
            toast("Please select shipping address", {
              position: "top-right",
              autoClose: 3000,
              type:'warning'
          })
          return;
          }
        } 

    let check = shippingAddressValidate(data);
    if(showForm)
    {
      if(check.validate.valid)
      {
        console.log(data)
        data['paymentMethod'] = paymentMethod;
        data['address_type'] = 'new';
        data['carts'] = products;
        data['offer']=offerApplicabe ? offerData.id : null 
        var formData = {
          'pth':'checkout',
          'data':data
        };  
        console.log("first new", formData)
        checkoutProcess(formData)
      }
      else{
        setErrName(check.validate.nm);
        setErrMsg(check.validate.msg);
      }
    }
    else{
      if(checkedVal)
        {
          var formDt = {
            'paymentMethod':paymentMethod,
            'address_type':'old',
            'address_id':checkedVal,
            'carts':products,
            'note':shippingNotes,
            'offer':offerApplicabe ? offerData.id : null 
          };
          var oldShippingAddress = {
            'pth':'checkout',
                'data':formDt
          };
            console.log("old ", oldShippingAddress)
           checkoutProcess(oldShippingAddress)
        }
        else
        {
          
          if(check.validate.valid)
            { 
              data['paymentMethod'] = paymentMethod;
              data['address_type'] = 'new';
              data['carts'] = products;
              data['offer'] = offerApplicabe ? offerData.id : null 
              var formData3 = {
                'pth':'checkout',
                'data':data
              };
              console.log("other new", formData3)
              checkoutProcess(formData3)
            }
            else
            {
            setErrName(check.validate.nm);
            setErrMsg(check.validate.msg);  
            }
        }
    }
  }

  const checkoutProcess = async (formData) => {
    setLoading(true);  // Ensure it's set to true before API call

    try {
        console.log("paymentMethod", paymentMethod);
        const headers = {
            'Accept': 'application/json',
        };

        const res = await api.post(formData.pth, formData.data, { headers });

        console.log("Got output from API", res.data);

        if (paymentMethod === 'payOnRazorPay') {
               
    //  setErr(false)
    //  setOrderMsg('')
    //  //setOrderId(res.data.order_id); 
    //  setActivateRazor(true); 
    //  setLoading(true);

            if (res.data.error === false) {   
                const task_id = res.data.order_id;
                if (task_id) {
                  setLoading(true);
                  setErr(false)
                  setOrderMsg('')
                  setOrderId(task_id);
                  setActivateRazor(true);  
                  
                  } else {
                    toast(res.data.msg, {
                      position: "top-right",
                      autoClose: 3000,
                      type:res.data.error ? 'warning' : 'success'
                  })
                  return;
                }
            } else {
                console.error("API response error:", res.data.msg);
                toast(res.data.msg, {
                  position: "top-right",
                  autoClose: 3000,
                  type:res.data.error ? 'warning' : 'success'
              })
              return;
           }
        } else {
          toast(res.data.msg, {
            position: "top-right",
            autoClose: 3000,
            type:res.data.error ? 'warning' : 'success'
        })
        if (!res.data.error) {
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
        }
        } 
    } catch (error) {
        console.error('Server Error:', error.message);
    } finally {
        setLoading(false); 
    }
};

  const onInputChange = (e)=>{
    setErrName('');
    let {name, value}= e.target;
    setData({...data, [name]:value});
  } 



const applyCode = async (e)=>{
  e.preventDefault();
  console.log(offerCode);
  try {
    const response = await papi.get('validate-offer?offer_code='+offerCode);
    console.log("offer response", response.data)
    if(response.data.error)
    {
      setOfferApplicable(false)
      toast(response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        type:'warning'
      })
    }
    else if(!response.data.error)
    {
      setOfferApplicable(true)
      setOfferData(response.data.output)
      toast(response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        type:'success'
      })
    }

  } catch (error) {
  
      console.log("error", error.response)
    }       
}  

  return ( 
    <>
    <Layout>
<div>
  {/*== Page Title Area Start ==*/}
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Checkout</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><Link className="active breadcrumb_active">Checkout</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}

  <div id="page-content-wrapper" className="p-9">
    <div className="container">
    {
      offer ? 

    <div class="row">
            <div class="col-12">
               
                <div class="checkoutaccordion" id="checkOutAccordion">
                  
                    <div class="card">
                        <h3>Have A Coupon? <span data-toggle="collapse" data-target="#couponaccordion">Click Here To Enter Your Code</span>
                        </h3>
                        <div id="couponaccordion" class="collapse" data-parent="#checkOutAccordion">
                            <div class="card-body">
                                <div class="cart-update-option">
                                    <div class="apply-coupon-wrapper">
                                        <form class=" d-block d-md-flex">
                                            <input type="text" name="offer_code" value={offerCode} onChange={(e)=>setOfferCode(e.target.value)} placeholder="Enter Your Coupon Code"/>
                                            <button class="btn-add-to-cart" onClick={applyCode}>Apply Coupon</button>
                                        </form>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>  
                </div>
              
            </div>
        </div>
        : <></>
      } 


      {/*== Checkout Page Content Area ==*/}
      <div className="row">
        {/* Checkout Billing Details */}
        <div className="col-lg-6">
          <div className="checkout-billing-details-wrap">
            <h2>Billing Details</h2>
            <div className="billing-form-wrap">
            {shippingAddress?.length > 0 ?  
            <>
            <div className="order-payment-method">
              {shippingAddress?.length > 0 && shippingAddress?.map((sh, index)=>(
                <div className="single-payment-method show" key={index}>
                <div className="payment-method-name">
                  <div className="custom-control custom-radio">
                    <input type="radio" id={sh?.id} name="shipping_address" value={sh?.id} checked={checkedVal === sh?.id} className="custom-control-input"  onChange={()=>{setCheckedVal(sh?.id); setShowForm(false)}} />
                    <label className="custom-control-label" htmlFor={sh?.id}>
                      {sh?.first_name + sh?.last_name +', '+sh?.address+', '+sh?.landmark+', '+sh?.pincode+', '+sh?.city+', '+sh?.contact }
                    </label>
                  </div>
                </div>
              
              </div>
              ))}
                                
              </div>
              <div className="single-input-item">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" onChange={(e)=>{setShowForm(!showForm); setCheckedVal('')}} checked={showForm} className="custom-control-input" id="ship_to_different" />
                      <label className="custom-control-label" htmlFor="ship_to_different">Ship to a different
                        address?</label>
                    </div>
                  </div>
              </>
              : ''}
              {showForm ?   
            <form>
                <div className="row">
                  <div className="col-md-6">
                    <MyInput2 inputType="input" type="text" name="fname" req={true} title="First Name" placeholder="First Name *" value={data.fname} onChnge={onInputChange} errName={errName} errMsg={errMsg} />
                  </div>
                  <div className="col-md-6">
                  <MyInput2 inputType="input" type="text" name="lname" req={true} title="Last Name" placeholder="Last Name *" value={data.lname} onChnge={onInputChange} errName={errName} errMsg={errMsg} />
                  </div>
                </div>

                <MyInput2 inputType="input" type="text" name="phone" req={false} title="Phone" placeholder="Phone" value={data.phone} onChnge={onInputChange} errName={errName} errMsg={errMsg} />  

                <MyInput2 inputType="input" type="text" name="address" req={true} title="Address" placeholder="Address *" value={data.address} onChnge={onInputChange} errName={errName} errMsg={errMsg} />

                <MyInput2 inputType="input" type="text" name="pincode" req={true} title="Pincode" placeholder="Pincode *" value={data.pincode} onChnge={onInputChange} errName={errName} errMsg={errMsg} />

                <MyInput2 inputType="input" type="text" name="landmark" req={true} title="landmark" placeholder="Landmark *" value={data.landmark} onChnge={onInputChange} errName={errName} errMsg={errMsg} />
                <MyInput2 inputType="input" type="text" name="city" req={true} title="city" placeholder="City *" value={data.city} onChnge={onInputChange} errName={errName} errMsg={errMsg} />

                <MyInput2 inputType="textarea"  name="note" req={false} title="Order Note" placeholder="Order Note " value={data.note} onChnge={onInputChange} errName={errName} errMsg={errMsg}  />
             </form>
             :
             <div className="single-input-item">
              <label htmlFor="note" className="">Order Note</label>
              <textarea name="note" placeholder="Order Note" value={shippingNotes} onChange={(e)=>setShippingNotes(e.target.value)}></textarea>
              </div>
             }
            </div>
          </div>
        </div>
        {/* Order Summary Details */}
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div className="order-summary-details">
            <h2>Your Order Summary</h2>
            <div className="order-summary-content">
              {/* Order Summary Table */}
              <div className="order-summary-table table-responsive text-center">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Products</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                  {products?.length>0 && products?.map((pr, index)=>(
                    <tr key={index}>
                    <td><a href={`/shop/${pr?.cart_dtl?.pth}`} target="_blank">{pr?.cart_dtl?.name} <strong> × {pr?.cart_dtl?.qty}</strong></a> 
                     {/* {pr?.cart_dtl?.stock > 0 ? <span className="text-success">In Stock</span> : <span className="text-danger">Out of Stock</span>} */}
                      </td>
                    
                    <td>
                    {pr?.cart_dtl?.stock > 0 ?
                      <span>₹{pr?.cart_dtl?.price * pr?.cart_dtl?.qty}</span>
                      
                  : <del>₹{pr?.cart_dtl?.price * pr?.cart_dtl?.qty}</del>}
                  </td>
                  </tr>
                  )) }
                
                    
                  </tbody>
                  <tfoot>
                    { offerApplicabe ?
                    <tr>
                      <td>Discount ({offerData.discount}%)</td>
                      <td><strong>
                      ₹ {state?.total_cart_amt && offerData?.discount
    ? Math.round((state.total_cart_amt / 100) * offerData.discount)
    : 0}

                        </strong></td>
                    </tr>
                     : <></> }  
                    <tr>
                      <td>Total Amount</td>
                      { offerApplicabe ?
                      <td><strong>
                       ₹ {state?.total_cart_amt && offerData?.discount
    ? state?.total_cart_amt - Math.round((state.total_cart_amt / 100) * offerData.discount)
    : 0}
                        </strong></td>
                        :
                        <td><strong>₹ {state?.total_cart_amt}</strong></td>
                        }
                      </tr>
                  </tfoot>
                </table>
              </div>
              <div className="order-payment-method">
                <div className="single-payment-method show">
                  <div className="payment-method-name">
                    <div className="custom-control custom-radio">
                      <input type="radio" id="cashOnDelivery" name="paymentmethod" value="cashOnDelivery" className="custom-control-input" checked={paymentMethod === 'cashOnDelivery'} onChange={() => setPaymentMethod('cashOnDelivery')} />
                      <label className="custom-control-label" htmlFor="cashOnDelivery">Cash On Delivery</label>
                    </div>
                  </div>
                
                </div>
                
                  <div className="single-payment-method">
                    <div className="payment-method-name">
                      <div className="custom-control custom-radio">
                        <input type="radio" id="payOnRazorPay" name="paymentmethod" value="payOnRazorPay" className="custom-control-input" checked={paymentMethod === 'payOnRazorPay'} onChange={() => setPaymentMethod('payOnRazorPay')}  />
                        <label className="custom-control-label" htmlFor="payOnRazorPay">Pay online </label>

                      </div>
                    </div>
                    
                  </div>
                  
                <div className="summary-footer-area">
                {
  state?.total_payable_item > 0 ? (
    paymentMethod === 'payOnRazorPay' ? (
      <RazorPayment
        type="button"
        name="submit"
        onClick={placeOrder}
        cls="btn-add-to-cart"
        value="Place Order online"
        loading={loading}
        err={err}
        errMsg={orderMsg}
        amount= { offerApplicabe ? state?.total_cart_amt - Math.round((state.total_cart_amt / 100) * offerData.discount) : state?.total_cart_amt} //{state?.total_cart_amt}
        products={products}
        orderId={orderId}
        activateRazor={activateRazor}  
        user={user}
        offer={offerApplicabe ? offerData.id : null}
      />
    ) : (
      <MyButton
        type="button"
        name="submit"
        onClick={placeOrder}
        cls="btn-add-to-cart"
        value="Place Order"
        loading={loading}
        err={err}
        errMsg={orderMsg}
      />
    )
  ) : null
}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*== Checkout Page Content End ==*/}
    </div>
  </div>
  {/*== Page Content Wrapper End ==*/}
</div>

    </Layout>
    </>
  )
}

export default Checkout