import React, { useEffect, useState } from 'react'
import Layout from '../partials/Layout'
import QuickView from '../partials/QuickView'
import {useDispatch} from 'react-redux'
import { getData, userCart, userWishlist } from '../../actions/Useraction';
import { Link } from 'react-router-dom';
import papi from '../../actions/papi';
function Shop() {
const [categories, setCategories] = useState([]);
const [products, setProducts] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(0);
const [itemsPerPage, setItemsPerPage] = useState(9); 
const [sortByName, setSortByName] = useState('');

const [prdt, setPrdt] = useState();
const dispatch = useDispatch();
const [loading, setLoading] = useState(false);

useEffect(()=>{
  fetchCategories()
  fetchProducts(currentPage, itemsPerPage, sortByName)
}, [dispatch, currentPage, itemsPerPage, sortByName])

const fetchCategories = async () => {
  try {
    const data = await dispatch(getData('get-categories?get=shop'));
    //console.log("rec ", data.payload.output);
    setCategories(data?.payload?.output)
    
  } catch (error) {
    console.error('Error fetching categories:', error);
  }
};

const handlePageChange = (page) => {
  if (page > 0 && page <= totalPages) {
    setCurrentPage(page);
  }
};

const handleItemsPerPageChange = (event) => {
  setItemsPerPage(parseInt(event.target.value));
  setCurrentPage(1); // Reset to first page when items per page changes
};


const fetchProducts = async (page = 1, perPage = itemsPerPage, sortBy) => {
  try {
    setLoading(true); 
    const headers = {
      'Accept': 'application/json',
    };
    const response = await papi.get('get-products', { 
      headers,
      params: { page, page_size: perPage, sortBy } 
    });
    console.log("fetched products", response.data.results);
    setProducts(response?.data?.results);
    setTotalPages(Math.ceil(response?.data?.count / perPage));
  } catch (error) {
    console.error('Error fetching products:', error);
  } finally {
    setLoading(false); 
  }
};

function overview(prt)
{
  //console.log(prt)
  setPrdt(prt);
}

const handleAddToCart = async(id)=>{
  try{
    var resp = {
      pth:'add-to-cart',
      data:{
        prd:id
      }
    }
    await dispatch(userCart(resp))
    
  }
  catch (error) {
    console.error('Error fetching products:', error, error);
  }
}

const handleAddToWishlist = async(id)=>{
  try{
    var resp = {
      pth:'add-to-wishlist',
      data:{
        prd:id
      }
    }
    await dispatch(userWishlist(resp))
    
  }
  catch (error) {
    console.error('Error fetching products:', error, error);
  }
}

const handleSortChange = (e) => {
  console.log("Sort changed to:", e.target.value); 
  const newSortBy = e.target.value;
   setSortByName(newSortBy ); 
   setCurrentPage(1); 
  //fetchProducts(1, itemsPerPage, newSortBy)
};

  return (
    <>
    <Layout>
<div>
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Shop</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/" className="active breadcrumb_active">Shop</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9">
    <div className="container">
      <div className="row">
        {/* Sidebar Area Start */}
        <div className="col-lg-3 mt-5 mt-lg-0 order-last order-lg-first">
          <div id="sidebar-area-wrap">
            {/* Single Sidebar Item Start */}
            <div className="single-sidebar-wrap">
              <h2 className="sidebar-title">Shop By</h2>
              <div className="sidebar-body">
                <div className="shopping-option">
                  {/* <h3>Shopping Options</h3> */}

                  <div className="shopping-option-item">
                    <h4>Categories</h4>
                    <ul className="sidebar-list">
                    <li key="0"><a href="/shop" className='activve'>All</a></li>
                    {
                      categories?.length > 0 ? categories?.map((ct, index)=>(
                        <li key={index+1}><a href={`/shop/${ct?.slug}`}>{ct?.name}</a></li>
                      )) : <></>
                    }
                    </ul>
                  </div>
            
                </div>
              </div>
            </div>
            {/* Single Sidebar Item End */}
            {/* Single Sidebar Item Start */}
            {/* <div className="single-sidebar-wrap">
              <h2 className="sidebar-title">My Wish List</h2>
              <div className="sidebar-body">
                <div className="small-product-list">
                  <div className="single-product-item">
                    <figure className="product-thumb">
                      <a href="#"><img className="mr-2 img-fluid" src="assets/img/product-2.jpg" alt="Products" /></a>
                    </figure>
                    <div className="product-details">
                      <h2><a href="single-product.html">Sprite Yoga Companion Kit</a></h2>
                      <span className="price">$6.00</span>
                    </div>
                  </div>
                  <div className="single-product-item">
                    <figure className="product-thumb">
                      <a href="single-product.html"><img className="mr-2 img-fluid" src="assets/img/product-3.jpg" alt="Products" /></a>
                    </figure>
                    <div className="product-details">
                      <h2><a href="single-product.html">Set of Sprite Yoga Straps</a></h2>
                      <span className="price">$88.00</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Single Sidebar Item End */}
            {/* Single Sidebar Item Start */}
            {/* <div className="single-sidebar-wrap">
              <h2 className="sidebar-title">MOSTVIEWED PRODUCTS</h2>
              <div className="sidebar-body">
                <div className="small-product-list">
                  <div className="single-product-item">
                    <figure className="product-thumb">
                      <a href="single-product.html"><img className="mr-2 img-fluid" src="assets/img/product-1.jpg" alt="Products" /></a>
                    </figure>
                    <div className="product-details">
                      <h2><a href="single-product.html">Beginner's Yoga</a></h2>
                      <span className="price">$50.00</span>
                    </div>
                  </div>
                  <div className="single-product-item">
                    <figure className="product-thumb">
                      <a href="single-product.html"><img className="mr-2 img-fluid" src="assets/img/product-2.jpg" alt="Products" /></a>
                    </figure>
                    <div className="product-details">
                      <h2><a href="single-product.html">Sprite Yoga Companion Kit</a></h2>
                      <span className="price">$6.00</span>
                    </div>
                  </div>
                  <div className="single-product-item">
                    <figure className="product-thumb">
                      <a href="single-product.html"><img className="mr-2 img-fluid" src="assets/img/product-3.jpg" alt="Products" /></a>
                    </figure>
                    <div className="product-details">
                      <h2><a href="single-product.html">Set of Sprite Yoga Straps</a></h2>
                      <span className="price">$88.00</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Single Sidebar Item End */}
          </div>
        </div>
        {/* Sidebar Area End */}
        {/* Shop Page Content Start */}
        <div className="col-lg-9">
          <div className="shop-page-content-wrap">
            <div className="products-settings-option d-block d-md-flex">
              <div className="product-cong-left d-flex align-items-center">
                <ul className="product-view d-flex align-items-center">
                  <li className="current column-gird"><i className="fa fa-bars fa-rotate-90" /></li>
                  <li className="box-gird"><i className="fa fa-th" /></li>
                  <li className="list"><i className="fa fa-list-ul" /></li>
                </ul>
                <span className="show-items">Items {products?.length > 0 ? products.length : 0}</span>
              </div>
              <select value={sortByName} onChange={handleSortChange} className='filterSelect'>
              <option value="">Relevance</option>
              <option value="name_asc">Name, A to Z</option>
              <option value="name_desc">Name, Z to A</option>
              <option value="price_asc">Price low to high</option>
              <option value="price_desc">Price high to low</option>
                </select>

              {/* <div className="product-sort_by d-flex align-items-center mt-3 mt-md-0">
                <label htmlFor="sort">Sort By:</label>
                <select name="sort" id="sort">
                  <option value="Position">Relevance</option>
                  <option value="Name Ascen">Name, A to Z</option>
                  <option value="Name Decen">Name, Z to A</option>
                  <option value="Price Ascen">Price low to heigh</option>
                  <option value="Price Decen">Price heigh to low</option>
                </select>
              </div> */}
            </div>
            <div className="shop-page-products-wrap">
              <div className="products-wrapper">
                <div className="row">
                {
                  loading ? <div className="loader-container">
                  <img src="/assets/img/loader.svg" alt="loader" />
                </div> :
                
                  products?.length > 0 ? products?.map((pr, index)=>(
                    <div className="col-lg-4 col-sm-6" key={index}>
                  
                    <div className="single-product-item text-center">
                      <figure className="product-thumb">
                        <a href={`/shop/product/${pr?.slug}`}><img src={pr?.imag} alt="Products" className="img-fluid" style={{ width:'195px', height:'230px' }} /></a>
                      </figure>
                      <div className="product-details">
                        <h2><a href={`/shop/product/${pr?.slug}`} title={pr?.name}>
                        {pr?.name?.length > 21 ? `${pr.name.slice(0, 21)}...` : pr?.name}
                        
                        </a></h2>
                        {/* <div className="rating">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star-half" />
                          <i className="fa fa-star-o" />
                        </div> */}
                        <span className="price">
                              ₹ {pr?.price} 
                              {pr?.old_price && pr?.old_price !== 0.0 && (
                                <>
                                  <s style={{ fontSize: '12px' }}> ₹{pr?.old_price}</s>
                                </>
                              )}
                            </span>
                          {
                            pr?.stock > 0 ? <span className="text-success">In Stock</span> : <span className="text-danger">Out of Stock</span>
                          }

                        <p className="products-desc" dangerouslySetInnerHTML={{ __html: pr?.short_desc }}></p>
                        
                        {
                          pr?.stock > 0 ? <Link onClick={()=>handleAddToCart(pr?.id)} className="btn btn-add-to-cart">+ Add to Cart</Link> : <Link className="btn btn-add-to-cart disable">+ Add to Cart</Link>
                        }
                        
                        <Link onClick={()=>handleAddToWishlist(pr?.id)} className="btn btn-add-to-cart btn-whislist">Wishlist</Link>
                        {/* <a href="single-product.html" className="btn btn-add-to-cart btn-whislist">+
                          Wishlist</a> */}
                        {/* <a href="single-product.html" className="btn btn-add-to-cart btn-compare">+
                          Compare</a> */}
                      </div>
                      <div className="product-meta">
                        <button type="button" data-toggle="modal" data-target="#quickView">
                          <span data-toggle="tooltip" onClick={()=>overview(pr)} data-placement="left" title="Quick View"><i className="fa fa-compress" /></span>
                        </button>
                        <Link onClick={()=>handleAddToWishlist(pr?.id)} data-toggle="tooltip" data-placement="left" title="Add to Wishlist"><i className="fa fa-heart-o" /></Link>
                        {/* <a href="#" data-toggle="tooltip" data-placement="left" title="Compare"><i className="fa fa-tags" /></a> */}
                      </div>
                      {/* <span className="product-bedge">New</span> */}
                    </div>
                
                  </div>                    
                  )) : <></>
                }
 
                </div>
              </div>
            </div>
            {
              products?.length > 0 ?
            
              <div className="products-settings-option d-block d-md-flex">
              <nav className="page-pagination">
                <ul className="pagination">
                  <li>
                    <a
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      «
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => (
                    <li key={i}>
                      <a
                        className={currentPage === i + 1 ? 'current' : ''}
                        href="#"
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      »
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="product-per-page d-flex align-items-center mt-3 mt-md-0">
                <label htmlFor="show-per-page">Show Per Page</label>
                <select name="sort" id="show-per-page" onChange={handleItemsPerPageChange}>
                  <option value={9}>9</option>
                  <option value={15}>15</option>
                  <option value={21}>21</option>
                  <option value={27}>27</option>
                </select>
              </div>
              </div>
            : <></> }
          </div>
        </div>
        {/* Shop Page Content End */}
      </div>
    </div>
  </div>
</div>

<QuickView prd={prdt} />
    </Layout>
    </>
  )
}

export default Shop