import Home from "../pages/Home";
import Shop from "../pages/Shop";
import Contact from "../pages/Contact";
import Login from '../pages/Login';
import Product from '../pages/Product';
import Cart from "../pages/Cart";
import Wishlist from "../pages/Wishlist";
import Checkout from "../pages/Checkout";
import About from "../pages/info_pages/About";
import Account from '../pages/Account'
import PageNotFound from '../pages/PageNotFound'
import ChangePassword from "../pages/ChangePassword";
import Logout from "../pages/Logout";
import ShopByCategory from "../pages/ShopByCategory";
import Privacy from "../pages/info_pages/Privacy";
import Terms from "../pages/info_pages/Terms";
import Refund from "../pages/info_pages/Refund";
import Shipping from "../pages/info_pages/Shipping";
import Celebration from "../pages/Celebration";

const publicRoute = [
    {
        path:'/',
        component:<Home />
    },
    {
        path:'/about',
        component:<About />
    },
    {
        path:'/privacy-policy',
        component:<Privacy />
    },
    {
        path:'/terms-conditions',
        component:<Terms />
    },
    {
        path:'/refund-policy',
        component:<Refund />
    },
    {
        path:'/shipping-policy',
        component:<Shipping />
    },        
    {
        path:'/shop',
        component:<Shop />  
    },
    {
        path:'/cart',
        component: <Cart />
    },
    {
        path:'/shop/:category',
        component:<ShopByCategory />
    },
    {
        path:'/shop/product/:product',
        component:<Product />
    },
    {
        path:'/celebration/:category',
        component:<Celebration />
    },
    {
        path:'/change-password/:id/:str',
        component:<ChangePassword />
    },
    // {
    //     path:'/profile',
    //     component:<Account />
    // },
    {
        path:'/contact',
        component:<Contact />
    },
    {
        path:'/login-signup',
        component:<Login />
    },
    {
        path:'/log-out',
        component:<Logout />
    },
    {
        path:'*',
        component:<PageNotFound />
    },
];

export default publicRoute;